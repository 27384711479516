<template>
  <div>
    <section class="first-block">
      <div class="content">
        <div class="first-block__row">
          <div class="first-block__text">
            <h1 class="first-block__caption">
              О чем
              <span class="first-block__caption_highlight">молчат</span>
              мужчины?! <br />
              2 этап
            </h1>
            <div class="first-block__subcaption">
              «У любой проблемы может быть решение»
            </div>
            <div class="first-block__description">
              Качество жизни – комплексный показатель и зависит от совместных
              усилий врача и пациента. Вы можете помочь пациенту с ССЗ повысить
              качество жизни.
            </div>
            <div class="first-block__buttons">
              <a
                href="#stages"
                class="first-block__button button button_empty-violet"
                @click.prevent="scrollTo('#stages')"
              >
                Присоединиться
              </a>
              <div class="first-block__button-tip">
                Проект для кардиологов и терапевтов
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        class="first-block__image"
        src="@/assets/img/betalock/firstscreen.png"
      />
    </section>
    <section class="second-block">
      <div class="content">
        <div class="second-block__main-row">
          <div class="second-block__col">
            <div class="second-block__row">
              <div class="second-block__text">
                Все большее значение имеют вопросы обеспечения качества жизни
                пациентов с ССЗ. Важные составляющие этого понятия - физическая
                активность и полноценная сексуальная жизнь<sup>¹ ²</sup>
                <br />
                <br />
                Страх осложнений может заставлять пациентов, отказываться от
                секса<sup>¹</sup> что может привести к ограничению или полному
                отказу от активного образа жизни. Кроме того, статистика ЭД у
                пациентов с ССЗ достаточно печальна.
              </div>
              <div class="second-block__violet-block violet-block">
                <div class="violet-block__square">
                  <div class="violet-block__text-container">58<sub>%</sub></div>
                </div>
                <div class="violet-block__text">
                  мужчин с ССЗ <br />страдают ЭД
                </div>
              </div>
              <div class="second-block__arrow">
                <svg
                  viewBox="0 0 102 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M101.061 13.0607C101.646 12.4749 101.646 11.5251 101.061 10.9393L91.5147 1.3934C90.9289 0.807611 89.9792 0.807611 89.3934 1.3934C88.8076 1.97919 88.8076 2.92893 89.3934 3.51472L97.8787 12L89.3934 20.4853C88.8076 21.0711 88.8076 22.0208 89.3934 22.6066C89.9792 23.1924 90.9289 23.1924 91.5147 22.6066L101.061 13.0607ZM0 13.5H100V10.5H0V13.5Z"
                    fill="#3E1876"
                  />
                </svg>
              </div>
              <div class="second-block__violet-block violet-block">
                <div class="violet-block__square">
                  <div class="violet-block__text-container">4<sub>x</sub></div>
                </div>
                <div class="violet-block__text">
                  Это в 4 раза чаще, <br />чем у здоровых лиц
                </div>
              </div>
            </div>
          </div>
          <div class="second-block__col">
            <div class="second-block__title">
              Эректильная дисфункция<sup>³ ⁴</sup>
            </div>
            <div class="second-block__grid">
              <div class="second-block__violet-block violet-block">
                <div class="violet-block__square">
                  <div class="violet-block__text-container">30<sub>%</sub></div>
                </div>
                <div class="violet-block__text">
                  у каждого третьего <br />мужчины с АГ
                </div>
              </div>
              <div class="second-block__violet-block violet-block">
                <div class="violet-block__square">
                  <div class="violet-block__text-container">50<sub>%</sub></div>
                </div>
                <div class="violet-block__text">
                  у каждого второго <br />мужчины с ИБС
                </div>
              </div>
              <div class="second-block__violet-block violet-block">
                <div class="violet-block__square">
                  <div class="violet-block__text-container">2<sub>/3</sub></div>
                </div>
                <div class="violet-block__text">
                  У двух из трех мужчин с сочетанием <br />этих заболеваний
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="third-block">
      <div class="content">
        <h2 class="third-block__caption">Вы можете помочь</h2>
        <div class="third-block__description">
          Как важна регулярная физической активность² для подавляющего
          большинства пациентов с ССЗ и как психологически важно мужчине «быть в
          строю»¹ <br /><br />
          Как повысить качество жизни пациента с ССЗ расскажут О.Д. Остроумова и
          С.В. Талашова!
        </div>
        <div class="third-block__row">
          <div class="third-block__speaker">
            <div class="speaker__avatar-container">
              <img
                class="speaker__avatar"
                src="@/assets/img/betalock/speaker1.png"
              />
            </div>
            <div class="speaker__text">
              <div class="speaker__name">Ольга Дмитриевна Остроумова</div>
              <div class="speaker__position">д.м.н., профессор</div>
              <div class="speaker__description">
                заведующая кафедрой терапии и полиморбидной патологии ФГБОУ ДПО
                «Российская медицинская академия непрерывного профессионального
                образования» Минздрава России
              </div>
            </div>
          </div>
          <div class="third-block__speaker">
            <div class="speaker__avatar-container">
              <img
                class="speaker__avatar"
                src="@/assets/img/betalock/speaker2.png"
              />
            </div>
            <div class="speaker__text">
              <div class="speaker__name">Талашова Светлана Вадимовна</div>
              <div class="speaker__position">
                Кандидат фармацевтических наук
              </div>
              <div class="speaker__description">
                директор Национальной Ассоциации Интегративной Медицины
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="stages-block">
      <div class="content">
        <div class="stages-block__title">Этапы проекта</div>
        <div class="stages-block__main-container">
          <div class="stage-block__container violet-container">
            <div class="stage-pos__block">
              <div class="num-block">
                <div class="stage-pos__num">01</div>
                <div class="stage-pos__text">этап проекта</div>
              </div>
              <div class="stage-title __mob">
                Рекомендации клинического фармаколога
              </div>
            </div>
            <div class="stage-content__block">
              <div class="stage-title">
                Рекомендации клинического фармаколога
              </div>
              <div class="stage-content__main">
                <div class="stage-content__left">
                  <div class="stage-content__text">
                    Как ССЗ влияет на сексуальную активность и как помочь
                    пациенту вести активную жизнь?
                  </div>
                  <div class="stage-content__buttons">
                    <div class="stage-video__btn">
                      <div class="btn-img">
                        <svg
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="23"
                            cy="23"
                            r="22"
                            stroke="white"
                            stroke-width="2"
                          />
                          <g clip-path="url(#clip0_4332_184260)">
                            <path
                              d="M29.2625 21.7095L18.2625 15.2064C17.3688 14.6783 16 15.1908 16 16.497V29.5002C16 30.672 17.2719 31.3783 18.2625 30.7908L29.2625 24.2908C30.2437 23.7127 30.2469 22.2877 29.2625 21.7095Z"
                              fill="currentColor"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4332_184260">
                              <rect
                                width="14"
                                height="16"
                                fill="currentColor"
                                transform="translate(16 15)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="btn-text">Смотреть видеоразбор</div>
                    </div>
                    <div class="stage-temp__btn button button_empty-white">
                      Читать статью
                    </div>
                  </div>
                </div>
                <div class="stage-content__right">
                  <div class="stage-content__text">
                    Физическая активность и сердечно-сосудистые заболевания.
                  </div>
                  <div class="stage-content__buttons">
                    <div class="stage-video__btn">
                      <div class="btn-img">
                        <svg
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="23"
                            cy="23"
                            r="22"
                            stroke="white"
                            stroke-width="2"
                          />
                          <g clip-path="url(#clip0_4332_184260)">
                            <path
                              d="M29.2625 21.7095L18.2625 15.2064C17.3688 14.6783 16 15.1908 16 16.497V29.5002C16 30.672 17.2719 31.3783 18.2625 30.7908L29.2625 24.2908C30.2437 23.7127 30.2469 22.2877 29.2625 21.7095Z"
                              fill="currentColor"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4332_184260">
                              <rect
                                width="14"
                                height="16"
                                fill="currentColor"
                                transform="translate(16 15)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="btn-text">Смотреть видеоразбор</div>
                    </div>
                    <div
                      class="stage-temp__btn button button-wide button_empty-white"
                    >
                      Клинический пример
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="stage-block__container">
            <div class="stage-pos__block">
              <div class="num-block">
                <div class="stage-pos__num">02</div>
                <div class="stage-pos__text">этап проекта</div>
              </div>
              <div class="stage-title __mob">Видеоразборы с профессором</div>
            </div>
            <div class="stage-content__block">
              <div class="stage-title">Видеоразборы с профессором</div>
              <div class="stage-content__main">
                <div class="stage-content__left">
                  <div class="stage-content__text">
                    «Как помочь пациенту сохранить физически активный образ
                    жизни без вреда для здоровья?»
                  </div>
                  <div class="stage-content__buttons">
                    <div class="stage-video__btn">
                      <div class="btn-img">
                        <svg
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="23"
                            cy="23"
                            r="22"
                            stroke="#3E1574"
                            stroke-width="2"
                          />
                          <g clip-path="url(#clip0_4332_184297)">
                            <path
                              d="M29.2625 21.7095L18.2625 15.2064C17.3688 14.6783 16 15.1908 16 16.497V29.5002C16 30.672 17.2719 31.3783 18.2625 30.7908L29.2625 24.2908C30.2437 23.7127 30.2469 22.2877 29.2625 21.7095Z"
                              fill="#3E1574"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4332_184297">
                              <rect
                                width="14"
                                height="16"
                                fill="currentColor"
                                transform="translate(16 15)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="btn-text">Смотреть видеоразбор</div>
                    </div>
                    <div class="stage-temp__btn button button_empty-violet">
                      Читать статью
                    </div>
                  </div>
                </div>
                <div class="stage-content__right">
                  <div class="stage-content__text">
                    Как подобрать препараты для лечения ССЗ, чтобы максимально
                    сохранить ЭФ мужчины и его веру в себя?
                  </div>
                  <div class="stage-content__buttons">
                    <div class="stage-video__btn">
                      <div class="btn-img">
                        <svg
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="23"
                            cy="23"
                            r="22"
                            stroke="#3E1574"
                            stroke-width="2"
                          />
                          <g clip-path="url(#clip0_4332_184297)">
                            <path
                              d="M29.2625 21.7095L18.2625 15.2064C17.3688 14.6783 16 15.1908 16 16.497V29.5002C16 30.672 17.2719 31.3783 18.2625 30.7908L29.2625 24.2908C30.2437 23.7127 30.2469 22.2877 29.2625 21.7095Z"
                              fill="#3E1574"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4332_184297">
                              <rect
                                width="14"
                                height="16"
                                fill="currentColor"
                                transform="translate(16 15)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="btn-text">Смотреть видеоразбор</div>
                    </div>
                    <div
                      class="stage-temp__btn button button-wide button_empty-violet"
                    >
                      Клинический пример
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="stage-block__container big-container violet-container">
            <div class="stage-main">
              <div class="stage-pos__block">
                <div class="num-block">
                  <div class="stage-pos__num">03</div>
                  <div class="stage-pos__text">этап проекта</div>
                </div>
                <div class="stage-title __mob">Видеоразборы с профессором</div>
              </div>
              <div class="stage-content__block">
                <div class="stage-title">Видеоразборы с профессором</div>
                <div class="stage-content__main">
                  <div class="stage-content__left">
                    <div class="stage-content__text">
                      Как сократить количество приступов стенокардии и возможно
                      ли это?
                    </div>
                    <div class="stage-content__buttons">
                      <div class="stage-video__btn">
                        <div class="btn-img">
                          <svg
                            width="46"
                            height="46"
                            viewBox="0 0 46 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23"
                              cy="23"
                              r="22"
                              stroke="white"
                              stroke-width="2"
                            />
                            <g clip-path="url(#clip0_4332_184260)">
                              <path
                                d="M29.2625 21.7095L18.2625 15.2064C17.3688 14.6783 16 15.1908 16 16.497V29.5002C16 30.672 17.2719 31.3783 18.2625 30.7908L29.2625 24.2908C30.2437 23.7127 30.2469 22.2877 29.2625 21.7095Z"
                                fill="currentColor"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4332_184260">
                                <rect
                                  width="14"
                                  height="16"
                                  fill="currentColor"
                                  transform="translate(16 15)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="btn-text">Смотреть видеоразбор</div>
                      </div>
                    </div>
                  </div>
                  <div class="stage-content__right">
                    <div class="stage-content__text">
                      Как минимизировать наступления острых случаев при ССЗ?
                    </div>
                    <div class="stage-content__buttons">
                      <div class="stage-video__btn">
                        <div class="btn-img">
                          <svg
                            width="46"
                            height="46"
                            viewBox="0 0 46 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23"
                              cy="23"
                              r="22"
                              stroke="white"
                              stroke-width="2"
                            />
                            <g clip-path="url(#clip0_4332_184260)">
                              <path
                                d="M29.2625 21.7095L18.2625 15.2064C17.3688 14.6783 16 15.1908 16 16.497V29.5002C16 30.672 17.2719 31.3783 18.2625 30.7908L29.2625 24.2908C30.2437 23.7127 30.2469 22.2877 29.2625 21.7095Z"
                                fill="currentColor"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4332_184260">
                                <rect
                                  width="14"
                                  height="16"
                                  fill="currentColor"
                                  transform="translate(16 15)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="btn-text">Смотреть видеоразбор</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="stage-sub">
              <div class="stage-pos__block">
                <div class="stage-pos__text">Передайте пациенту</div>
              </div>
              <div class="stage-content__block">
                <div class="stage-content__main">
                  <div class="stage-content__left">
                    <div class="stage-content__text">
                      Рекомендации по питанию для больных с ССЗ
                    </div>
                    <div class="stage-content__buttons">
                      <div class="stage-temp__btn button button_empty-white">
                        Читать статью
                      </div>
                    </div>
                  </div>
                  <div class="stage-content__right">
                    <div class="stage-content__text">
                      Гимнастика для пациентов с ССЗ
                    </div>
                    <div class="stage-content__buttons">
                      <div class="stage-temp__btn button button_empty-white">
                        Читать статью
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="stages-block" id="stages">
      <div class="content">
        <div class="stages-block__title">Этапы проекта</div>
        <div class="stages-block__main-container">
          <div
            class="stage-block__container big-container violet-container mb-6"
          >
            <div class="stage-main">
              <div class="stage-pos__block">
                <div class="num-block">
                  <div class="stage-pos__num">02</div>
                  <div class="stage-pos__text mt-8">этап проекта</div>
                </div>
                <div class="stage-title __mob">Дискуссионый клуб</div>
              </div>
              <div class="stage-content__block">
                <div class="stage-title">Дискуссионый клуб</div>
                <div class="stage-content__main top-sect mt-4">
                  <div class="stage-content__left">
                    <div class="stage-content__text">
                      Диалог экспертов о выборе тактики ведения коморбидных
                      пациентов с ИБС
                    </div>
                    <div class="stage-content__buttons">
                      <div
                        class="stage-temp__btn button button_empty-white"
                        @click="
                          watchClick(
                            'https://storage.yandexcloud.net/az-most.ru-storage/media/content/betalok/%D0%91%D0%B5%D1%82%D0%B0%D0%BB%D0%BE%D0%BA%20%D0%97%D0%9E%D0%9A_%D0%94%D0%B8%D1%81%D0%BA%D1%83%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B9%20%D0%BA%D0%BB%D1%83%D0%B1_approved_070324-min.mp4',
                            'Диалог экспертов о выборе тактики ведения коморбидных пациентов с ИБС'
                          )
                        "
                      >
                        <svg
                          class="mr-4"
                          width="15"
                          height="17"
                          viewBox="0 0 15 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.7625 7.41331L2.7625 0.910189C1.86875 0.382064 0.5 0.894564 0.5 2.20081V15.2039C0.5 16.3758 1.77188 17.0821 2.7625 16.4946L13.7625 9.99456C14.7437 9.41644 14.7469 7.99144 13.7625 7.41331Z"
                            fill="currentColor"
                          />
                        </svg>

                        Смотреть
                      </div>
                    </div>
                  </div>
                  <!-- <div class="stage-content__right">
                    <div class="stage-content__text">
                      Как ССЗ влияет на сексуальную активность и как помочь
                      пациенту вести активную жизнь?
                    </div>
                    <div class="stage-content__buttons">
                      <div
                        @click="
                          watchClick(
                            'https://az-most.ru/library/videomaterials/kak-ssz-vliyayut-na-seksualnuyu-aktivnost-talashova-s-v?access=d101a8dbcb1da66f9cd23681825a287a91b10c85ad5e03b884431b8c0e37cf8a',
                            'Как ССЗ влияет на сексуальную активность и как помочь пациенту вести активную жизнь?'
                          )
                        "
                        class="stage-temp__btn button button_empty-white"
                      >
                        <svg
                          class="mr-4"
                          width="15"
                          height="17"
                          viewBox="0 0 15 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.7625 7.41331L2.7625 0.910189C1.86875 0.382064 0.5 0.894564 0.5 2.20081V15.2039C0.5 16.3758 1.77188 17.0821 2.7625 16.4946L13.7625 9.99456C14.7437 9.41644 14.7469 7.99144 13.7625 7.41331Z"
                            fill="currentColor"
                          />
                        </svg>

                        Смотреть
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="stage-sub">
              <div class="stage-pos__block">
                <div class="stage-pos__text">Дополнительные материалы</div>
              </div>
              <div class="stage-content__block">
                <div class="stage-content__main">
                  <div class="stage-content__left">
                    <div class="stage-title substage-title">
                      Мнение кардиолога
                    </div>
                    <div class="stage-content__left-inner">
                      <div class="stage-content__left-container">
                        <div class="stage-content__text">
                          Как помочь пациенту сохранить физически активный образ
                          жизни без вреда для здоровья?
                        </div>
                        <div
                          @click="
                            watchClick(
                              'https://az-most.ru/library/videomaterials/kak-pomoch-pacientu-sohranit-fizicheski-aktivnyj-obraz-zhizni-bez-vreda-dlya-zdorovya',
                              'Как помочь пациенту сохранить физически активный образ жизни без вреда для здоровья'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div
                            class="stage-temp__btn button button_empty-white"
                          >
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7625 7.41331L2.7625 0.910189C1.86875 0.382064 0.5 0.894564 0.5 2.20081V15.2039C0.5 16.3758 1.77188 17.0821 2.7625 16.4946L13.7625 9.99456C14.7437 9.41644 14.7469 7.99144 13.7625 7.41331Z"
                                fill="currentColor"
                              />
                            </svg>

                            Смотреть
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__left-container">
                        <div class="stage-content__text">
                          Как подобрать препараты для лечения ССЗ и терапию ЭД?
                          Вернуть уверенность мужчины в себя?
                        </div>
                        <div
                          @click="
                            watchClick(
                              'https://az-most.ru/library/videomaterials/kak-podobrat-preparaty-dlya-lecheniya-ssz-i-terapii-ehd-chtoby-sohranit-ehrektilnuyu-funkciyu-muzhchiny',
                              'Как подобрать препараты для лечения ССЗ и терапию ЭД. Вернуть уверенность мужчины в себя.'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div
                            class="stage-temp__btn button button_empty-white"
                          >
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7625 7.41331L2.7625 0.910189C1.86875 0.382064 0.5 0.894564 0.5 2.20081V15.2039C0.5 16.3758 1.77188 17.0821 2.7625 16.4946L13.7625 9.99456C14.7437 9.41644 14.7469 7.99144 13.7625 7.41331Z"
                                fill="currentColor"
                              />
                            </svg>

                            Смотреть
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__left-container">
                        <div class="stage-content__text">
                          Как снизить риск развития осложнений
                          сердечно-сосудистых заболеваний?
                        </div>
                        <div
                          @click="
                            watchClick(
                              'https://az-most.ru/library/videomaterials/kak-snizit-risk-razvitiya-oslozhnenij-ssz',
                              'Как снизить риск развития осложнений сердечно-сосудистых заболеваний?'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div
                            class="stage-temp__btn button button_empty-white"
                          >
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7625 7.41331L2.7625 0.910189C1.86875 0.382064 0.5 0.894564 0.5 2.20081V15.2039C0.5 16.3758 1.77188 17.0821 2.7625 16.4946L13.7625 9.99456C14.7437 9.41644 14.7469 7.99144 13.7625 7.41331Z"
                                fill="currentColor"
                              />
                            </svg>

                            Смотреть
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__left-container">
                        <div class="stage-content__text">
                          Лечение стенокардии: цели ближайшие и отдаленные
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/library/videomaterials/lechenie-stenokardii-celi-blizhajshie-i-otdolennye',
                              'Лечение стенокардии: цели ближайшие и отдаленные'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div
                            class="stage-temp__btn button button_empty-white"
                          >
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7625 7.41331L2.7625 0.910189C1.86875 0.382064 0.5 0.894564 0.5 2.20081V15.2039C0.5 16.3758 1.77188 17.0821 2.7625 16.4946L13.7625 9.99456C14.7437 9.41644 14.7469 7.99144 13.7625 7.41331Z"
                                fill="currentColor"
                              />
                            </svg>

                            Смотреть
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="stage-content__right">
                    <div class="stage-title substage-title">
                      Клинические случаи
                    </div>
                    <div class="stage-content__right-inner">
                      <div class="stage-content__right-container">
                        <div class="stage-content__text">
                          Идеальный выбор бета-блокатора для использования в
                          составе комбинированой терапии
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/library/textmaterials/beta-blokatory-v-lechenie-pacienta-s-ibs-i-postinfarktnym-kardiosklerozom',
                              'Идеальный выбор бета-блокатора для использования в составе комбинированой терапии'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div
                            class="stage-temp__btn button button_empty-white"
                          >
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__right-container">
                        <div class="stage-content__text">
                          Пациент перенесенным инфарктом миокарда. Почему
                          бета-блокатор?
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/library/textmaterials/idealnyj-vybor-beta-adrenoblokatora-dlya-ispolzovaniya-v-sostave-kombinirovannoj-terapii',
                              'Пациент перенесенным инфарктом миокарда. Почему бета-блокатор?'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div
                            class="stage-temp__btn button button_empty-white"
                          >
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__right-container">
                        <div class="stage-content__text">
                          Пациент с артериальной гипертонией. Когда добавлять
                          бета-блокаторы?
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/library/textmaterials/beta-blokatory-i-arterialnaya-gipertenziya-kogda-dobavlyat',
                              'Пациент с артериальной гипертонией. Когда добавлять бета-блокаторы'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div
                            class="stage-temp__btn button button_empty-white"
                          >
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="stage-block__container big-container violet-container_empty"
          >
            <div class="stage-main">
              <div class="stage-pos__block">
                <div class="num-block">
                  <div class="stage-pos__num">01</div>
                  <div class="stage-pos__text mt-8">этап проекта</div>
                </div>
                <div class="stage-title __mob">
                  Рекомендации клинического фармаколога
                </div>
              </div>
              <div class="stage-content__block">
                <div class="stage-title">
                  Рекомендации клинического фармаколога
                </div>
                <div class="stage-content__main top-sect mt-4">
                  <div class="stage-content__left">
                    <div class="stage-content__text">
                      Физическая активность и сердечно-сосудистые заболевания.
                    </div>
                    <div class="stage-content__buttons">
                      <div
                        class="stage-temp__btn button button_violet"
                        @click="
                          watchClick(
                            'https://az-most.ru/library/videomaterials/fizicheskaya-aktivnost-i-ssz-talashova-s-v?access=721dfc87b2169fbd837a8042134a32505388c45243ef445a43bf2b880c62894c',
                            'Физическая активность и сердечно-сосудистые заболевания.'
                          )
                        "
                      >
                        <svg
                          class="mr-4"
                          width="15"
                          height="17"
                          viewBox="0 0 15 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.7625 7.41331L2.7625 0.910189C1.86875 0.382064 0.5 0.894564 0.5 2.20081V15.2039C0.5 16.3758 1.77188 17.0821 2.7625 16.4946L13.7625 9.99456C14.7437 9.41644 14.7469 7.99144 13.7625 7.41331Z"
                            fill="currentColor"
                          />
                        </svg>

                        Смотреть
                      </div>
                    </div>
                  </div>
                  <div class="stage-content__right">
                    <div class="stage-content__text">
                      Как ССЗ влияет на сексуальную активность и как помочь
                      пациенту вести активную жизнь?
                    </div>
                    <div class="stage-content__buttons">
                      <div
                        @click="
                          watchClick(
                            'https://az-most.ru/library/videomaterials/kak-ssz-vliyayut-na-seksualnuyu-aktivnost-talashova-s-v?access=d101a8dbcb1da66f9cd23681825a287a91b10c85ad5e03b884431b8c0e37cf8a',
                            'Как ССЗ влияет на сексуальную активность и как помочь пациенту вести активную жизнь?'
                          )
                        "
                        class="stage-temp__btn button button_violet"
                      >
                        <svg
                          class="mr-4"
                          width="15"
                          height="17"
                          viewBox="0 0 15 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.7625 7.41331L2.7625 0.910189C1.86875 0.382064 0.5 0.894564 0.5 2.20081V15.2039C0.5 16.3758 1.77188 17.0821 2.7625 16.4946L13.7625 9.99456C14.7437 9.41644 14.7469 7.99144 13.7625 7.41331Z"
                            fill="currentColor"
                          />
                        </svg>

                        Смотреть
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="stage-sub">
              <div class="stage-pos__block">
                <div class="stage-pos__text">Дополнительные материалы</div>
              </div>
              <div class="stage-content__block">
                <div class="stage-content__main">
                  <div class="stage-content__left">
                    <div class="stage-title substage-title">
                      Интересно для врача
                    </div>
                    <div class="stage-content__left-inner">
                      <div class="stage-content__left-container">
                        <div class="stage-content__text">
                          Выявление ЭД у пациентов с сердечно-сосудистыми
                          заболеваниями
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/media/uploads/materials/pfd/2023/03/30/%D0%92%D1%8B%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5_%D0%AD%D0%94_%D1%83_%D0%BF%D0%B0%D1%86%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2_%D1%81_%D0%A1%D0%A1%D0%97.pdf?access=7321e3b6ef86bbe642c3c5fe5cafc8b5b4eb95df92e1107971a28c97d2290fa9',
                              'Выявление ЭД у пациентов с сердечно-сосудистыми заболеваниями'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div class="stage-temp__btn button button_violet">
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__left-container">
                        <div class="stage-content__text">
                          Как повысить приверженность терапии у больных с
                          сердечно-сосудистыми заболеваниями?
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/media/uploads/materials/pfd/2023/03/30/%D0%9A%D0%B0%D0%BA_%D0%BF%D0%BE%D0%B2%D1%8B%D1%81%D0%B8%D1%82%D1%8C_%D0%BF%D1%80%D0%B8%D0%B2%D0%B5%D1%80%D0%B6%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D1%8C_%D1%82%D0%B5%D1%80%D0%B0%D0%BF%D0%B8%D0%B8_%D1%83_%D0%B1%D0%BE%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D1%81_%D0%A1%D0%A1%D0%97.pdf?access=b4937ce594a13b519169f12e394c8721623de70155c58691e2c06bc5be0ef4ff',
                              'Как повысить приверженность терапии у больных с сердечно-сосудистыми заболеваниями?'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div class="stage-temp__btn button button_violet">
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__left-container">
                        <div class="stage-content__text">
                          Физические нагрузки для людей с сердечно-сосудистыми
                          заболеваниями
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/media/uploads/materials/pfd/2023/03/30/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F_%D0%BD%D0%B0%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B0_%D0%B4%D0%BB%D1%8F_%D0%BB%D1%8E%D0%B4%D0%B5%D0%B9_%D1%81_%D0%A1%D0%A1%D0%97.pdf?access=eec96d0fd8e40df04355e0855791ed09d05c3bbbd07895b4daeb843ad8dae62e',
                              'Физические нагрузки для людей с сердечно-сосудистыми заболеваниями'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div class="stage-temp__btn button button_violet">
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__left-container">
                        <div class="stage-content__text">
                          Диетотерапия для людей с ССЗ
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/media/uploads/materials/pfd/2023/03/30/%D0%94%D0%B8%D0%B5%D1%82%D0%B0_%D0%BF%D1%80%D0%B8_%D0%B7%D0%B0%D0%B1%D0%BE%D0%BB%D0%B5%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F%D1%85_%D0%A1%D0%A1_%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D1%8B.pdf?access=fe7fa4b7051adf5f428a315cca8e700dbb1a6b71f5b4e718f987ffec45b93e63',
                              'Диетотерапия для людей с ССЗ'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div class="stage-temp__btn button button_violet">
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="stage-content__right">
                    <div class="stage-title substage-title">
                      Поделиться с пациентом
                    </div>
                    <div class="stage-content__right-inner">
                      <div class="stage-content__right-container">
                        <div class="stage-content__text">
                          Лечебная физкультура (ЛФК) при стенокардии
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/media/uploads/materials/pfd/2023/03/30/%D0%9B%D0%B5%D1%87%D0%B5%D0%B1%D0%BD%D0%B0%D1%8F_%D1%84%D0%B8%D0%B7%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0_%D0%9B%D0%A4%D0%9A_%D0%BF%D1%80%D0%B8_%D1%81%D1%82%D0%B5%D0%BD%D0%BE%D0%BA%D0%B0%D1%80%D0%B4%D0%B8%D0%B8.pdf?access=a3829adcd13c9be26d6e0fb7d062884331f670db29516afc9f2646d9990942eb',
                              'Лечебная физкультура (ЛФК) при стенокардии'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div class="stage-temp__btn button button_violet">
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                      <div class="stage-content__right-container">
                        <div class="stage-content__text">
                          Основные принципы питания для людей с ССЗ
                        </div>
                        <div
                          @click="
                            textClick(
                              'https://az-most.ru/media/uploads/materials/pfd/2023/03/30/%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D0%B5_%D0%BF%D1%80%D0%B8%D0%BD%D1%86%D0%B8%D0%BF%D1%8B_%D0%BF%D0%B8%D1%82%D0%B0%D0%BD%D0%B8%D1%8F_%D0%B4%D0%BB%D1%8F_%D0%BB%D1%8E%D0%B4%D0%B5%D0%B9_%D1%81_%D0%A1%D0%A1%D0%97.pdf?access=e9abe6decfa8bb937d2bb19d6e9d3cc4c82ddef1c9b9a6e767a8f655314f0b35',
                              'Основные принципы питания для людей с ССЗ'
                            )
                          "
                          class="stage-content__buttons"
                        >
                          <div class="stage-temp__btn button button_violet">
                            <svg
                              class="mr-4"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_4955_154177)">
                                <g clip-path="url(#clip1_4955_154177)">
                                  <path
                                    d="M16.54 14.8338C16.31 15.3138 16.1 15.4238 15.58 15.3138C13.97 14.9638 12.34 14.7938 10.69 14.9138C10.05 14.9638 9.41002 15.0738 8.77002 15.1538C8.85002 15.0838 8.94002 14.9938 9.04002 14.9138C9.83002 14.2838 10.74 13.8938 11.72 13.7138C12.36 13.5938 13.02 13.5438 13.68 13.4938C14.38 13.4438 14.9 13.1538 15.22 12.5238C15.36 12.2538 15.39 11.9538 15.39 11.6438C15.39 9.00379 15.39 6.37379 15.39 3.73379C15.39 3.65379 15.39 3.58379 15.39 3.50379C16.03 3.42379 16.31 3.47379 16.54 3.98379C16.54 7.62379 16.54 11.2238 16.54 14.8338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M6.74998 14.6438C6.69998 14.6038 6.66998 14.5838 6.63998 14.5538C5.51998 13.4538 4.13998 12.8738 2.60998 12.6338C2.04998 12.5438 1.46998 12.5238 0.899983 12.4838C0.429983 12.4538 0.169983 12.2038 0.169983 11.7238C0.169983 8.4638 0.169983 5.2138 0.169983 1.9538C0.169983 1.4538 0.459983 1.1938 0.959983 1.2038C1.88998 1.2338 2.80998 1.3438 3.69998 1.6538C4.89998 2.0638 5.89998 2.7538 6.58998 3.8238C6.67998 3.9638 6.74998 4.1538 6.74998 4.3238C6.75998 7.7238 6.75998 11.1238 6.75998 14.5338C6.75998 14.5638 6.74998 14.5938 6.74998 14.6438Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M7.78002 14.6538C7.78002 14.5438 7.78002 14.4638 7.78002 14.3938C7.78002 11.1438 7.79002 7.89379 7.77002 4.64379C7.77002 4.15379 7.93002 3.78379 8.22002 3.43379C9.13002 2.31379 10.34 1.69379 11.74 1.41379C12.36 1.29379 13 1.25379 13.64 1.20379C14.07 1.17379 14.36 1.46379 14.36 1.90379C14.36 5.19379 14.36 8.47379 14.36 11.7638C14.36 12.2038 14.08 12.4638 13.62 12.4738C12.72 12.4838 11.84 12.5938 10.98 12.8338C9.81002 13.1538 8.76002 13.7038 7.89002 14.5638C7.87002 14.5838 7.84002 14.6038 7.78002 14.6538Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M-0.85 3.49377C-0.85 3.59377 -0.85 3.66377 -0.85 3.74377C-0.85 6.38377 -0.85 9.03377 -0.85 11.6738C-0.85 12.2738 -0.66 12.7738 -0.18 13.1438C0.14 13.3938 0.5 13.4938 0.9 13.5038C1.66 13.5238 2.4 13.5938 3.14 13.7838C4.07 14.0238 4.92 14.4038 5.66 15.0438C5.7 15.0838 5.75 15.1238 5.83 15.2038C5.65 15.1638 5.52 15.1338 5.4 15.1138C5.27 15.0938 5.14 15.0638 5.01 15.0438C3.88 14.8638 2.75 14.8438 1.62 14.9238C0.7 14.9938 -0.21 15.1438 -1.11 15.3338C-1.65 15.4438 -2 15.1438 -2 14.6138C-2 11.1638 -2 7.70377 -2 4.24377C-2 3.76377 -1.74 3.50377 -1.25 3.50377C-1.12 3.49377 -1 3.49377 -0.85 3.49377Z"
                                    fill="currentColor"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_4955_154177">
                                  <rect
                                    width="14"
                                    height="16"
                                    fill="currentColor"
                                    transform="translate(0.5 0.703796)"
                                  />
                                </clipPath>
                                <clipPath id="clip1_4955_154177">
                                  <rect
                                    width="18.54"
                                    height="14.15"
                                    fill="currentColor"
                                    transform="translate(-2 1.2038)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Прочитать
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="fourth-block">
      <div class="content">
        <div class="fourth-block__tip">
          1. Безопасность сексуальной активности у больных сердечно-сосудистыми
          заболеваниями. Е.Б. Мазо, С.И. Гамидов, Р.И. Овчинников, В.В.
          Иремашвили. ФАРМАТЕКА »» № 11 – 2004.
          <br />
          <br />
          2. Pelliccia et al. Eur Heart J. 2020. doi: 10.1093/eurheartj/ehaa605.
          <br />
          <br />
          3. Методические рекомендации. Сердечно-сосудистые заболевания и
          сексуальные расстройства у мужчин: диагностика и лечение коморбидного
          состояния. Мамедов М.Н., Поддубская Е.А., Шарвадзе Г.Г./ Москва; 2011
          г. С.: 40.
          <br />
          <br />
          4. Физическая активность при сердечно-сосудистых заболеваниях. ФГБУ
          «НМИЦ ТПМ» Минздрава России. 27.03.2021 г.
          <a
            href="https://gnicpm.ru/articles/zdorovyj-obraz-zhizni/fizicheskaya-aktivnost-pri-serdechno-sosudistyh-zabolevaniyah.html"
            >https://gnicpm.ru/articles/zdorovyj-obraz-zhizni/fizicheskaya-aktivnost-pri-serdechno-sosudistyh-zabolevaniyah.html</a
          >. Дата обращения – 09.01.2023 г.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Betalok",
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        window.scrollTo({
          behavior: "smooth",
          top: element.offsetTop,
        });
      }
    },
    watchClick(url, title) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "sp watch click", {
          "sp watch click": {
            "material name": title,
            ...this.$root.ymFields,
          },
        });
      }
      window.open(url, "_blank");
    },
    textClick(url, title) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "sp read click", {
          "sp read click": {
            "material name": title,
            ...this.$root.ymFields,
          },
        });
      }
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="css" scoped>
.button_empty-violet {
  color: #3e1876;
  border: 2px solid #3e1876;
}

.button_empty-violet:hover {
  color: #fff;
  border: 2px solid #3e1876;
  background-color: #3e1876;
}

.button_violet {
  color: #fff;
  border: 2px solid #3e1876;
  background-color: #3e1876;
}

.button_violet:hover {
  color: #3e1876;
  border: 2px solid #3e1876;
  background-color: #fff;
}

.content {
  width: 1580px;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 1919px) {
  .content {
    width: 1170px;
    margin: 0 auto;
    position: relative;
  }
}

@media screen and (max-width: 1199px) {
  .content {
    width: 100%;
    padding: 0 76px;
  }
}

@media screen and (max-width: 767px) {
  .content {
    width: 100%;
    padding: 0 16px;
  }
}

.first-block {
  height: 838px;
  position: relative;
}

.first-block__caption {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 64px;
  color: #121212;
}

.first-block__caption_highlight {
  color: #3e1574;
}

.first-block__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 870px;
  height: 838px;
  object-fit: contain;
  object-position: center;
}

.first-block__row {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 141px;
  width: 50%;
}

.first-block .content {
  height: 100%;
}

.first-block__subcaption {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #121212;
  margin-bottom: 25px;
}

.first-block__description {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #494949;
  margin-bottom: 70px;
}

.first-block__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.first-block__button {
  margin-right: 40px;
  width: 200px;
}

.first-block__button-tip {
  max-width: 212px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
}

@media screen and (max-width: 1440px) {
  .first-block__image {
    width: 730px;
  }
}

@media screen and (max-width: 1199px) {
  .first-block {
    margin-top: 100px;
    height: auto;
    position: relative;
  }

  .first-block__row {
    width: 100%;
    height: 100%;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .first-block__image {
    padding: 0 135px;
    position: static;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .first-block__button {
    margin-right: 33px;
    width: 279px;
  }
}

@media screen and (max-width: 767px) {
  .first-block {
    margin-top: 80px;
  }

  .first-block__row {
    margin-bottom: 15px;
  }

  .first-block__caption {
    font-size: 40px;
    line-height: 50px;
  }

  .first-block__description {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .first-block__buttons {
    margin-top: 25px;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .first-block__button {
    width: 100%;
    margin-right: 0;
    margin-top: 15px;
  }

  .first-block__image {
    padding: 0 16px;
  }

  .first-block__button-tip {
    max-width: 100%;
  }
}

.second-block {
  position: relative;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.07);
  padding: 70px 0;
}

.second-block__row {
  display: grid;
  grid-template-columns: 280px auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-gap: 25px 0px;
  align-items: center;
}

.second-block__text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #494949;
  grid-column: 1 / 4;
}

.second-block__row .violet-block {
  width: 100%;
}

.second-block__arrow {
  margin-right: 50px;
}

.second-block__arrow svg {
  height: 24px;
}

.second-block__main-row {
  display: grid;
  grid-template-columns: minmax(280px, 850px) 1fr auto;
}

.second-block__main-row .second-block__col:nth-child(2) {
  grid-column: 3/4;
}

.second-block__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  grid-gap: 30px 50px;
}

.second-block__title {
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
}

.violet-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.second-block__grid .violet-block:last-child {
  grid-column: 1 / 3;
}

.violet-block__square {
  width: 80px;
  height: 80px;
  background: #3e1876;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
}

.violet-block__text-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.violet-block sub {
  display: block;
  line-height: initial;
  font-weight: 400;
}

.violet-block__text {
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
}

@media screen and (max-width: 1919px) {
  .second-block__main-row {
    display: grid;
    grid-template-columns: minmax(200px, 600px) 1fr auto;
  }

  .second-block__row {
    grid-template-columns: 200px auto 1fr;
    grid-gap: 25px;
  }

  .second-block__arrow {
    margin-right: 0px;
  }

  .second-block__arrow svg {
    width: 70px;
  }

  .violet-block__square {
    width: 60px;
    height: 60px;
    font-size: 28px;
    line-height: 36px;
  }

  .second-block__grid {
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 30px 50px;
  }
}

@media screen and (max-width: 1199px) {
  .second-block__main-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 60px;
  }

  .second-block__row {
    grid-template-columns: 240px auto 1fr;
  }

  .violet-block__square {
    width: 80px;
    height: 80px;
    font-size: 36px;
    line-height: 44px;
  }

  .second-block__main-row .second-block__col:nth-child(2) {
    grid-column: auto;
  }
}

@media screen and (max-width: 767px) {
  .second-block {
    padding: 40px 0;
  }

  .second-block__main-row {
    grid-gap: 40px;
  }

  .second-block__row {
    grid-template-columns: 116px auto 1fr;
    grid-template-rows: 1fr 150px;
    grid-gap: 30px 0;
    align-items: start;
  }

  .second-block__text {
    width: 100%;
  }

  .second-block__row .violet-block {
    flex-direction: column;
    align-items: flex-start;
  }

  .violet-block__text {
    margin-left: 15px;
  }

  .second-block__row .violet-block .violet-block__text {
    margin-left: 0;
    margin-top: 10px;
  }

  .second-block__row .violet-block .violet-block__text br {
    display: none;
  }

  .second-block__arrow {
    margin-right: 30px;
    margin-top: 30px;
  }

  .second-block__grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  .second-block__grid .violet-block:last-child {
    grid-column: auto;
  }

  .second-block__grid .violet-block:last-child .violet-block__text {
    max-width: 248px;
  }

  .second-block__grid .violet-block:last-child .violet-block__text br {
    display: none;
  }
}

.third-block {
  padding: 120px 0;
}

.third-block__caption {
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #121212;
}

.third-block__description {
  margin-bottom: 40px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #494949;
}

.third-block__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.third-block__speaker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(50% - 100px);
}

.speaker__avatar-container {
  width: 127px;
  height: 127px;
  margin-right: 24px;
  flex-shrink: 0;
}

.speaker__avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.speaker__name {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #3e1574;
}

.speaker__position {
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #494949;
}

.speaker__description {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #494949;
}

@media screen and (max-width: 1199px) {
  .third-block__row {
    flex-direction: column;
  }

  .third-block__speaker {
    width: 100%;
  }

  .third-block__speaker:nth-child(2) {
    margin-top: 45px;
  }

  .speaker__avatar-container {
    margin-right: 50px;
  }
}

@media screen and (max-width: 767px) {
  .third-block {
    padding: 60px 0;
  }

  .third-block__speaker {
    flex-direction: column;
    align-items: flex-start;
  }

  .speaker__avatar-container {
    margin-bottom: 10px;
  }
}

.stages-block__title {
  margin-bottom: 45px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #121212;
}

.violet-container {
  background-color: #3e1876;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  color: #ffffff;
}

.violet-container_empty {
  border: 1px solid #3e1876;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  color: #3e1876;
}

.stage-block__container {
  padding: 70px 78px;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 5%;
}

.stage-block__container.big-container {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-gap: 40px;
}

.stage-block__container.big-container .line {
  width: 100%;
  opacity: 0.25;
  border-top: 1px solid #3e1574;
}

.stage-block__container.big-container.violet-container .line {
  border-color: #ffffff;
}

.stage-block__container.big-container .stage-main,
.stage-block__container.big-container .stage-sub {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 5%;
}

.stage-pos__block .num-block {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  justify-items: center;
}

.stage-pos__num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 90px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 120px;
  line-height: 96px;
  color: #3e1876;
}

.violet-container .stage-pos__num {
  color: #ffffff;
  opacity: 0.5;
}

.stage-pos__text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.stage-content__main {
  display: grid;
  grid-template-columns: minmax(311px, 500px) auto;
  grid-gap: 5%;
}

.top-sect .stage-content__left,
.top-sect .stage-content__right {
  display: grid;
  grid-gap: 20px;
}

.stage-content__left-inner,
.stage-content__right-inner {
  display: grid;
  grid-gap: 35px;
}

.stage-content__left-container,
.stage-content__right-container {
  display: grid;
  align-content: space-between;
  grid-template-rows: repeat(2, auto);
  grid-gap: 20px;
  min-height: 105px;
}

.stage-content__text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.stage-content__buttons {
  display: flex;
  height: 45px;
}

.stage-video__btn {
  margin-right: 35px;
  display: flex;
  align-items: center;
}

.stage-video__btn .btn-img {
  margin-right: 15px;
  width: 46px;
  height: 46px;
}

.stage-temp__btn {
  width: 190px;
}

.stage-temp__btn.button-wide {
  width: 251px;
}

.stage-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 20px;
}

.stage-title.__mob {
  display: none;
}

@media screen and (max-width: 1440px) {
  .stage-block__container {
    padding: 35px 39px;
  }

  .stage-content__main {
    display: grid;
    grid-template-columns: minmax(311px, 430px) auto;
    grid-gap: 5%;
  }
}

@media screen and (max-width: 1199px) {
  .stage-block__container {
    padding: 40px 32px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 40px;
  }

  .stage-pos__block {
    display: grid;
    grid-template-columns: 110px minmax(250px, 380px);
    grid-template-rows: 116px;
    grid-gap: 40px;
  }

  .stage-pos__block .num-block {
    grid-column: 1/2;
  }

  .stage-pos__num {
    width: 85px;
    height: 96px;
    font-size: 80px;
    line-height: 96px;
  }

  .stage-pos__text {
    margin-top: 0;
  }

  .stage-title {
    display: none;
  }

  .stage-title.substage-title {
    display: block;
    margin-bottom: 30px;
  }

  .stage-content__left-inner,
  .stage-content__right-inner {
    grid-gap: 40px;
  }

  .stage-title.__mob {
    display: grid;
    grid-column: 2/3;
    align-items: center;
  }

  .stage-content__main {
    margin-top: 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 45px;
  }

  .stage-content__left,
  .stage-content__right {
    grid-template-rows: repeat(2, auto);
  }

  .stage-temp__btn {
    width: 251px;
  }

  .stage-block__container.big-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-gap: 40px;
  }

  .stage-block__container.big-container .stage-main {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 40px;
  }

  .stage-block__container.big-container .stage-sub {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 25px;
  }

  .stage-block__container.big-container .stage-sub .stage-pos__block {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 40px;
  }
}

@media screen and (max-width: 767px) {
  .stage-block__container {
    padding: 32px 16px;
  }

  .stage-pos__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .stage-block__container.big-container {
    grid-gap: 20px;
  }

  .stage-content__buttons {
    flex-direction: column;
  }

  .stage-content__main {
    grid-gap: 40px;
  }

  .stage-video__btn {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .stage-block__container.big-container .stage-video__btn {
    margin-bottom: 0;
  }

  .stage-temp__btn {
    width: 100%;
  }

  .stage-temp__btn.button-wide {
    width: 100%;
  }
}

.fourth-block {
  padding-bottom: 80px;
}

.fourth-block__tip {
  margin-top: 120px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #494949;
}

.fourth-block__tip a {
  color: #3e1574;
}

@media screen and (max-width: 767px) {
  .fourth-block {
    padding-bottom: 60px;
  }
  .fourth-block__tip {
    margin-top: 60px;
  }
}
</style>
